import * as React from 'react'
import Ben from "../images/ben.jpg"
import Donald from "../images/donald.jpg"
import Kevin from "../images/Kevin.jpg"
import Terrance from "../images/terrance.jpeg"
import KinCircle from "../images/kin-circle.webp"
import { StaticImage } from "gatsby-plugin-image";

function AboutUs() {
    return (
        <div className={"bg-accent"}>
            <img loading={"lazy"} className={"py-24 pl-24 w-fit object-fill"} src={KinCircle} alt={"circles"} />
            <div className={"flex flex-row flex-wrap max-w-6xl mx-auto "}>
                <div className={"flex flex-col "}>
                    <div className={"max-w-lg h-fit"}>
                        <h2 className={"text-4xl px-4"}>We provide you with the knowledge to take your health into your own hands staying active and doing the things you love to do.</h2>
                        <a href={"http://kineticimpact.janeapp.com/"}><p className={"py-8 pl-4"}>Book a consultation -&gt;</p></a>
                    </div>
                    <div className={"p-10 pt-0 "}><StaticImage formats={["webp"]} loading={"lazy"} className={"lg:max-w-[28rem]"} src={"../images/ben.jpg"} alt={"Dr. Ben DeLuca"} />
                        <div className={"text-white lg:p-4 -mt-18"}>

                            <p className={"text-3xl p-4 lg:p-0"}>Dr. Ben DeLuca, DC</p><p className={"text-xl px-4 lg:p-0"}>Owner</p>
                            <p className={"text-lg py-6 lg:max-w-[26rem] text-black"}>Dr. Benjamin Deluca D.C. (Dr. Ben) started his career in wellness in 2000 as a personal trainer. With the objective to help people hit their fitness goals. Early in his career Dr. Ben suffered a major knee injury. Which inspired him to learn about strength training and exercise. Dr. Ben was driven to help more people with injuries get in shape and become confident with movement. His passion for continued learning and desire to help people eventually led him to Southern California University of Health Sciences (SCUHS). Where he received his doctorate in chiropractic medicine. Dr. Ben continues to seek new information in rehab techniques and strength and conditioning to improve outcomes for his patients. </p>
                            <p className={"text-lg py-6 lg:max-w-[26rem] text-black"}>
                                “I have learned to look at my own Injuries as a gift. It is an opportunity to learn, adapt, and get better. We must believe things can get better. My career has come full circle. I'm a movement advocate for my patients and clients. Our visits have become a partnership in finding a plan that works for them.” - Dr. Ben
                            </p>
                            <a href={"http://kineticimpact.janeapp.com/"}><p className={"text-xl"}>Book a consultation -&gt;</p></a>
                        </div>
                    </div>
                    <div className={"p-10 pt-0 "}><StaticImage formats={["webp"]} loading={"lazy"} className={"lg:max-w-[28rem]"} src={"../images/terrance.jpeg"} alt={"Dr. Terence Crossan"} />
                        <div className={"text-white lg:p-4 -mt-18"}>
                            <p className={"text-3xl p-4 lg:p-0"}>Dr. Terence Crossan DPT</p><p className={"text-xl px-4 lg:p-0"}>Physical Therapist</p>
                            <p className={"text-lg py-6 lg:max-w-[26rem] text-black"}>Terence received his Doctor of Physical Therapy degree from Columbia University College of Physicians and Surgeons. His passion for rehabilitation and athletics has led him to a career in physical therapy, with a goal of helping all clients and patients become active participants in a healthy lifestyle. He has experience treating individuals of all ages and fitness levels, from little leaguers and various levels of professional athletes in the MLB, NFL, and PLL.</p>
                            <p className={"text-lg py-6 lg:max-w-[26rem] text-black"}>When not treating and training clients, Terence enjoys playing volleyball, pickleball, and softball. Terence is a competitive volleyball player and coach. His coaching experience spans over a decade with experience ranging between high school and club levels to his most recent experience coaching collegiately for Columbia University Women’s Volleyball program in the 2018 season. In December 2018, he had the opportunity to attend a tryout for the USAV Men’s National Team. Terence continues to play volleyball professionally in the Volleyball League of America as owner and member of the New York Lost Travelers.</p>
                            <a href={"http://kineticimpact.janeapp.com/"}><p className={"text-xl"}>Book a consultation -&gt;</p></a>
                        </div>
                    </div>
                    </div>
                    <div className={"flex flex-col"}>
                    <div className={"p-10 pt-0 "}><StaticImage formats={["webp"]} loading={"lazy"} className={"lg:max-w-[28rem]"} src={"../images/donald.jpg"} alt={"Dr. Donald Mull"} />
                        <div className={"text-white lg:p-4 -mt-18"}>
                            <p className={"text-3xl p-4 lg:p-0"}>Dr. Donald Mull, DC</p><p className={"text-xl px-4 lg:p-0"}>Owner</p>
                            <p className={"text-lg py-6 lg:max-w-[26rem] text-black"}>Dr. Mull has grown up playing multiple sports, eventually playing college football for the University of La Verne here in Southern California. Being active his entire life, he understands how important it is to keep pain from reaching physical goals. Through his own injuries, his passion now lies in educating and providing care for your nagging injuries that keep you from living the active lifestyle you desire.He earned a Bachelor’s degree in Kinesiology at the University of La Verne and a Doctorate of Chiropractic at Southern California University. He specializes in rehab therapy, certified through Rehab 2 Performance.</p>
                            <p className={"text-lg py-6 lg:max-w-[26rem] text-black"}>“Your goals are always placed on my highest priority. However we can get you to achieve your health and fitness goals ultimately dictates your treatment plan. This allows you to maintain safe activity levels that will encourage long term health and wellness.” His experience and education allows him to aid the recovery process and educate you in the introduction of safe, pain FREE activity. He places priority on educating patients how to take your health into your own hands through movement and minor lifestyle modifications.</p>
                            <a href={"http://kineticimpact.janeapp.com/"}><p className={"text-xl"}>Book a consultation -&gt;</p></a>
                        </div>
                    </div>
                    <div className={"p-10 pt-0 "}><StaticImage formats={["webp"]} loading={"lazy"} className={"lg:max-w-[28rem]"} src={"../images/Kevin.jpg"} alt={"Dr. Kevin Leung"} />
                        <div className={"text-white lg:p-4 -mt-18"}>
                            <p className={"text-3xl p-4 lg:p-0"}>Dr. Kevin Leung, DPT</p><p className={"text-xl px-4 lg:p-0"}>Physical Therapist</p>
                            <p className={"text-lg py-6 lg:max-w-[26rem] text-black"}>Dr. Kevin PT, DPT, was born and raised in New York City. With an empire state of mind, he began his education at the University at Buffalo with a Bachelor of Science degree in Psychology. He later pursued his Doctoral degree in San Diego with honors from the University of St. Augustine for Health Sciences. Dr. Kevin has always had a passion for maximizing the potential of others. He worked as a sports performance coach, where he helped youth, weekend warriors, Olympians, and professional athletes perform at their highest level and reach their most significant achievements.</p>
                            <p className={"text-lg py-6 lg:max-w-[26rem] text-black"}>Dr. Kevin uses an insightful blend of his clinical training and coaching repertoire to help his clients bridge the gap between injury and attaining high performance. Dr. Kevin has a passion for assisting individuals in improving their physical literacy with hopes of facilitating a long, healthy lifestyle. He is devoted to delivering care to his clients with the elaborate intent of making them feel prioritized and supported. In his spare time, Dr. Kevin enjoys competing in powerlifting, traveling to new places, and searching for puppies.</p>
                            <a href={"http://kineticimpact.janeapp.com/"}><p className={"text-xl"}>Book a consultation -&gt;</p></a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default AboutUs